.Loading {
    margin: 0 auto;
    width: 70px;
    text-align: center;
    z-index: 99999;
}
.Loading>div {
    width: 18px;
    height: 18px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s ease-in-out infinite both;
    animation: sk-bouncedelay 1.4s ease-in-out infinite both;
}
.Loading .Loading__dot-1 {
    -webkit-animation-delay: -.32s;
    animation-delay: -.32s;
}
.Loading .Loading__dot-2 {
    -webkit-animation-delay: -.16s;
    animation-delay: -.16s;
}