 body.login,
html {
    height: 100%;

    background-image: url("https://images.unsplash.com/photo-1508873699372-7aeab60b44ab?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=900&ixid=MnwxfDB8MXxyYW5kb218fHx8fHx8fHwxNjIzMzY2OTE5&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1600");


    min-height: 380px;


    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

* {
    box-sizing: border-box;
} 


.form-demo {
    width: 100vw;
    height: 100vh;
}

.form-demo .card {
    max-width: 50%;
    min-width: 350px;
}
.form-demo .card form {
    margin-top: 2rem;
}
.form-demo .card .p-field {
    margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
    .form-demo .card {
        width: 100%;
        min-width: 350px;
    }
}
@media screen and (max-width: 640px) {
    .form-demo .card {
        width: 100%;
        min-width: 350px;
    }
}
