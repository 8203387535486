/* Dashboard */
.dashboard {

    .summary {
        position: relative;

        .title {
            font-size: 15px;
            text-align: center;
            padding-right: 10%;
        }

        .detail {
            color: $textSecondaryColor;
            display: block;
            margin-top: 10px;
        }

        .count {
            color: #ffffff;
            
            font-size: 20px;
            padding: 7px 14px;
            border-radius: $borderRadius;

            &.aceptado {
                background-color: #20d077;
            }

            &.abierto {
                background-color: #007be5;
            }
            
            &.revision {
                background-color: #b5c717;
            }

            &.detalle {
                background-color: #f31708;
            }


        }
    }

    .highlight-box {
        height: 100px;
        display: flex;
        @include clearfix();

        .initials {
            height: 100%;
            width: 50%;
            text-align: center;
            padding: 1em;

            > span {
                font-size: 48px;
            }
        }

        .highlight-details {
            height: 100%;
            background-color: #ffffff;
            width: 50%;
            padding: 1em;

            i {
                font-size: 18px;
                vertical-align: middle;
                margin-right: .5em;
            }

            .count {
                color: $textSecondaryColor;
                font-size: 36px;
                margin-top: 4px;
                display: block;
            }
        }
    }
.title-template{
    color: #007be5;
    font-weight:bold;
}
    
}
